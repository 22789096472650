@import './datepicker.css';
@import './scrollbars.css';
@import 'react-toastify/dist/ReactToastify.css';
@import '@next-level-ai/ai-agents-conversation-workflow/index.css';

:root {
  font-size: 10px;
  font-family: 'Roboto', sans-serif;

  /* --main: #7f56d9;
  --button-main: #7f56d9;
  --button-main-hover: #6941c6; */
  --main: #8358fa;
  --button-main: #8358fa;
  --button-main-hover: #6a49c5;

  --table-main: #5c6677;

  --danger: #ff7777;
  --darkBorder: #9cdba6;

  --main-text: #101828;
  --dark: #f1f3f5;
  --light: #686868;
  --contrast: #ffffff;
  --secondary: #667085;

  --border: #d0d5dd;
  --background: #eff3f2;

  --button-light: #f9f5ff;

  --success: #61d956;
  --progress: #f2bd2792;
  --error: #e0282e99;
}

.transition-default {
  @apply transition-all duration-200 delay-sm;
}
.transition-non-position {
  @apply transition-def duration-200 delay-sm;
}
.card-item-with-hover {
  @apply bg-contrast hover:border-main border-2 border-transparent rounded-card shadow-sm transition-default;
}
.tablet-item-with-hover {
  @apply bg-contrast hover:border-main border-2 border-transparent rounded-tablet shadow-sm transition-default;
}

[type='checkbox'].checkbox-simple:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

/* Remove up/down buttons in input[type=number] for wider compatibility */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  @apply appearance-none;
}
