:root {
  --scrollbar-thumb-padding: 0.4rem;
  --scrollbar-interactive-thumb-padding: 0.2rem;
  --scrollbar-size: 1.4rem;
  --scrollbar-thumb-color: var(--secondary);
  --scrollbar-thumb-hover-color: var(--secondary);
  --scrollbar-thumb-active-color: var(--secondary);
  --scrollbar-track-color: transparent;
  --scrollbar-track-hover-color: transparent;
}

*::-webkit-scrollbar {
  width: var(--scrollbar-size);
  height: var(--scrollbar-size);
  background: var(--scrollbar-track-color);
}

*::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}
*::-webkit-scrollbar-track:hover {
  background: var(--scrollbar-track-hover-color);
}
*::-webkit-scrollbar-button {
  display: none !important;
  visibility: hidden !important;
  border-color: transparent !important;
}

*::-webkit-scrollbar-thumb:vertical {
  border-left: var(--scrollbar-thumb-padding) solid transparent;
  border-right: var(--scrollbar-thumb-padding) solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

*::-webkit-scrollbar-thumb:horizontal {
  border-top: var(--scrollbar-thumb-padding) solid transparent;
  border-bottom: var(--scrollbar-thumb-padding) solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--scrollbar-thumb-color);
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
  --scrollbar-thumb-padding: var(--scrollbar-interactive-thumb-padding);

  background: var(--scrollbar-thumb-hover-color);
  background-clip: padding-box !important;
}

*::-webkit-scrollbar-thumb:vertical:active,
*::-webkit-scrollbar-thumb:horizontal:active {
  --scrollbar-thumb-padding: var(--scrollbar-interactive-thumb-padding);

  background: var(--scrollbar-thumb-active-color);
  background-clip: padding-box !important;
}

*::-webkit-scrollbar-corner {
  background: var(--scrollbar-track-color);
}

@supports (-webkit-scrollbar: none) {
  * {
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scrollbar-width: thin;
  }

  *:hover {
    scrollbar-color: var(--scrollbar-thumb-hover-color)
      var(--scrollbar-track-hover-color);
    scrollbar-width: thin;
  }
}
