@import 'react-clock/dist/Clock.css';
@import 'react-calendar/dist/Calendar.css';
@import 'react-datetime-picker/dist/DateTimePicker.css';

.react-calendar {
  @apply bg-contrast shadow-sm border border-solid border-border rounded-bt py-[1.2rem] px-[1.8rem] !important;
}

.react-calendar__tile {
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.react-datetime-picker__wrapper {
  @apply border-0 !important;
}

.react-datetime-picker__inputGroup {
  @apply text-left !important;
  font-size: 1.4rem !important;
}

.react-datetime-picker__inputGroup__input:invalid {
  @apply bg-buttonLight !important;
}

.react-datetime-picker__clock {
  @apply shadow-common border-0 rounded-sm bg-main !important;
}

.react-clock__mark__body,
.react-clock__hand__body {
  @apply bg-mainText !important;
}

.react-calendar__tile--active {
  @apply bg-main !important;
}

.react-calendar__tile--now:not(.react-calendar__tile--active) {
  @apply bg-buttonLight border border-solid border-buttonLight !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  @apply bg-buttonLight text-mainText !important;
}
.react-calendar__tile:disabled,
.react-calendar__navigation button:disabled {
  @apply bg-border text-buttonLightText opacity-20 !important;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #757575 !important;
}
